import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj10 = () => {
  return (
    <Layout>
      <SEO
        title="Natural_language_processing-Author_stylometry"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>
      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-10">
          <div className="py-1-heading">
            <h1>Natural_language_processing-Author_stylometry</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Natural%20Language%20Processing-Author%20Stylometry"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Stylometry is the quantitive study of literary style.
              <br />
              It is based on the observation that author tend to write in
              relatively consistent, recognizable and unique ways.
              <br />
              <br />
              Here, we have a collection of some written pages of some authors
              and also some are from unknown and also some are written by more
              than one author.
              <br />
              We have to analyse the style of all diffrent authors.
              <br />
              Well it's done through knowing the word length distribution.
              <br />
              <br />
              There are more advanced concept than this like:-
              <br />
              Kilgariff's chi-squared method <br />
              John Burrow's delta method
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Nltk</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj10
